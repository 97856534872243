export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const GET_RECLIST = 'GET_RECLIST';
export const RECLIST_ERROR = 'RECLIST_ERROR';
export const CLEAR_RECLIST = 'CLEAR_RECLIST';
export const GET_VIEWLIST = 'GET_VIEWLIST';

export const SEARCH_ACTIVE = 'SEARCH_ACTIVE';
export const GET_SEARCH_RES = 'GET_SEARCH_RES';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const CLEAR_SEARCH_RES = 'CLEAR_SEARCH_RES';

export const UPDATE_LIKES = 'UPDATE_LIKES';

export const GET_PINNEDLIST = 'GET_PINNEDLIST';
export const PINNEDLIST_ERROR = 'PINNEDLIST_ERROR';
export const CLEAR_PINNEDLIST = 'CLEAR_PINNEDLIST';

export const VIEWLIST_CLENSE = 'VIEWLIST_CLENSE';
